.dcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConfiLanguage {
  flex-direction: column;
  padding-top: 1%;
  width: 100%;
}

.ConfiLanguageNoAdmin {
  flex-direction: column;
  padding-top: 5%;
  width: 100%;
}

.chatList {
  background-color: #F5F2EC;
  padding: 5;
  border: '1px solid #ddd';
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.UserListView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.UserListInsideView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.UsrListInput {
  width: 100px;
  margin-right: 10px;
}

.UserListButton {
  margin-right: 5px;
}

.LoginContainer {
  width: 50%;
  /* border: 1px solid black; */
  box-shadow: 0 12px 16px 0 rgba(216, 215, 215, 0.616),
    0 17px 50px 0 rgba(233, 232, 232, 0.452);
  border-radius: 5px;
  flex-direction: column;
  background-color: white;
}

.LoginUsername {
  width: 80%;
  height: auto;
  margin-bottom: 25px;
  padding-top: 8%;
  flex-direction: row;
}

.LoginUsernameLabel {
  width: 10%;
  font-weight: bold;
}

.LoginInputText {
  text-align: center;
  height: 30px;
}

.LoginUsername2 {
  width: 80%;
  height: auto;
  flex-direction: row;
}

/* button */
.pushable {
  background: #14A851;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
}
.front {
  display: block;
  padding: 5px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #215D38;;
  color: white;
  will-change: transform;
  transition: transform 150ms;
}

.pushable:hover .front {
    transform: translateY(-6px);
  }

.pushable:active .front {
  transform: translateY(-2px);
}

/* button */

/* button2 */
.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button2 {
  min-width: 115px;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 520;
  color: white;
  background-color: #0d6efd;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.button2:hover {
  background-color: #12c27c;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.616);
  color: #fff;
  transform: translateY(-7px);
}

.buttonAdd {
  min-width: 115px;
  padding: 10px;
  padding-left: 22px;
  padding-right: 22px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 520;
  color: white;
  background-color: #198754;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.buttonAdd:hover {
  background-color: #12c2b9;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.616);
  color: #fff;
  transform: translateY(-7px);
}

.buttonDelete {
  min-width: 115px;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 520;
  color: white;
  background-color: #DC3545;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.buttonDelete:hover {
  background-color: red;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.616);
  color: white;
  transform: translateY(-7px);
}
/* button2 */

.loadingimages {
  width: 300px;
  height: 300px;
  border-radius: 50px;
}

.StyleDatePickerMobile {
  border: 1px solid #b9b9b9;
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding: 4px;
}

.PasswordEye{
  width: 40px;
  height: 40px;
}

.buttonshadow:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.616),
    0 17px 50px 0 rgba(0, 0, 0, 0.452);
    border-radius: 10px;
}

.StartBackground {
  width: 100%;
  background-color: #efefef;
  height: 100vh;
  flex-direction: column;
  overflow-x: auto;
}

.MessageContainer{
  padding: 15px;
  width: 40%;
  box-shadow: 2px 2px 2px rgba(231, 227, 227, 0.527),
  2px 2px 2px rgba(255, 251, 251, 0.26);
    border-radius: 10px;
    margin: 10px;
  /* overflow-y: auto; */
  /* white-space: pre-wrap; */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.inputOutline {
  outline: none;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.ChangePasswordContainer {
  display: flex;
  flex-direction: column;
  padding: 50px;
  border: 1px solid transparent;
  width: 40%;
  border-radius: 20px;
  box-shadow: 0 12px 16px 0 rgba(216, 215, 215, 0.616),
    0 17px 50px 0 rgba(233, 232, 232, 0.452);
}

.LoginContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid transparent;
  width: 35%;
  border-radius: 20px;
  box-shadow: 0 12px 16px 0 rgba(216, 215, 215, 0.616),
    0 17px 50px 0 rgba(233, 232, 232, 0.452);
}

.BoxShadow {
  border: 1px solid transparent;
  box-shadow: 8px 10px 5px 0 rgba(221, 221, 221, 0.616),
    15px 10px 5px 0 rgba(214, 214, 214, 0.767);
}

.ChangePAsswordTextView{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  width: 100%;
}

.USernameLogin {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.ChangePassName {
  width: 200px;
  font-size: 20px;
}

.ChangePassInput {
  display: flex;
  flex-direction: row;
  width: 40%;
}

/* Configuration */
.ChangeConfiguration {
  width: 50%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.ChangeConfigurationInput {
  display: flex;
  flex-direction: row;
  width: 50%;
  text-align: center;
}
/* Configuration */

.AlertPage {
  width: 70%;
  left: 250px;
}

.TableDisplay {
  display: flex;
  flex-direction: row;
}

.WorkLeftTable {
  margin-right: 1%;
  width: 29%;
}

.SearchDataHeaderView {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
}
.SearchDataHeaderViewCon {
  display: flex;
  align-items: center;
  width: 60%;
  flex-direction: row;
}
.ChatIconLabelDesign {
  display: flex;
  flex-direction: row;
}
.ChatSearchTextbox {
  margin-right: 10;
  background-color: 'white';
  width: 100%;
}
.ChatList {
  width: 100%;
  overflow-x: auto;
}

.HomePageViewImage {
  width: 40%;
  height: 40%;
  /* padding: 30px; */
  margin: 50px;
  margin-bottom: 30px;
}

.TitleSize {
  font-size: 32px;
  margin-right: 10px;
}

.TotalFontSize {
  font-size: 20px;
}

.ListFontSize {
  font-size: 16px;
}

.HomePageUserButton {
  width: 35%;
  border: 1px solid black;
  cursor: pointer;
}

.HomePageUserListFlex {
  display: flex;
  flex-direction: row;
}
.HomePageUserListfirst {
  margin-bottom: 0px;
  margin-right: 5px;
}

.image {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
/* .backgroundImage {
  background-image: url(./image/Scan_BG_D2.png);
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  background-color: #FFE0CC;
} */
@media (max-width: 1600px) {
  .TitleSize {
    font-size: 30px;
  }
  .TotalFontSize {
    font-size: 19px;
  }
  .ListFontSize {
    font-size: 15px;
    font-weight: 550;
  }
}
@media (max-width: 1400px) {
  .TitleSize {
    font-size: 29px;
  }
  .TotalFontSize {
    font-size: 18px;
  }
  .button2 {
    font-size: 15px;
  }
  .MoveButtonSize {
    font-size: 15px;
  }
}
@media (max-width: 1300px) {
  .TitleSize {
    font-size: 28px;
  }
  .ListFontSize {
    font-size: 14px;
    font-weight: 550;
  }
}
@media (max-width: 1200px) {
  .TitleSize {
    font-size: 27px;
  }
  .ListFontSize {
    font-size: 13.5px;
    font-weight: 550;
  }
  .button2 {
    font-size: 14px;
  }
  .MoveButtonSize {
    font-size: 14px;
  }
}

@media (max-width: 750px) {
  .ChatIconLabelDesign {
    display: flex;
    flex-direction: column;
  }
  .ChatSearchTextbox {
    /* margin-right: 5; */
    background-color: 'white';
    width: 150px;
  }
  .ChatList {
    width: 650px;
    overflow-x: auto;
  }
  .UserListView {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .SearchDataHeaderView {
    flex-direction: column;
  }
  .SearchDataHeaderViewCon {
    flex-direction: column;
  }
  .HomePageViewImage {
    width: 100%;
    height: 100%;
  }
  .WorkLeftTable {
    width: 100%;
  }
  .TableDisplay {
    flex-direction: column;
  }
  .UserListInsideView {
    flex-direction: column;
    width: 100%;
  }
  .UsrListInput {
    width: 70%;
  }
  .UserListButton {
    margin-top: 10px;
  }
  .ConfiLanguage {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .ConfiLanguageNoAdmin {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .LoginContainer {
    width: 100%;
  }
  .LoginUsername {
    flex-direction: column;
  }
  .LoginUsernameLabel {
    width: 100%;
  }
  .LoginUsername2 {
    flex-direction: column;
  }
  .ChangePasswordContainer{
    width: 100%;
    border: 1px solid transparent;
  }
  .LoginContainer{
    width: 100%;
    border: 1px solid transparent;
  }
  .ChangePAsswordTextView{
    flex-direction: column;
    margin-bottom: 20px;
    /* align-items: center; */
    width: 100%;
  }
  .USernameLogin {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
  }
  .ChangePassName {
    width: 100%;
  }
  .ChangePassInput{
    width: 100%;
  }
  .AlertPage {
    width: 100%;
    left: 0px;
  }
  .ChangeConfiguration {
    width: 100%;
  }
  .ChangeConfigurationInput {
    width: 100%;
  }
  .HomePageUserButton {
    width: 80%;
  }
  .HomePageUserListFlex {
    flex-direction: column;
  }
  .HomePageUserListfirst {
    margin-bottom: 5px;
    margin-right: 0px;
  }
}

  /* Button Move */
  .btnMove {
    min-width: 75px;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border: 4px solid black;
    height: 'auto';
    width: 'auto';
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.9s;
  }

  .MoveButtonSize{
    font-size: 16px;
  }

  .b1{
    background: -webkit-linear-gradient(0deg,white 10%, #0d6efd 10%);
    background-size: auto auto;
    border: 4px solid #0d6efd;
    color: white;
  }

  .b1:hover{
    background-position: 200px 0;
    box-shadow: 0 20px 16px 0 rgba(0, 0, 0, 0.616),
      0 8px 50px 0 rgba(209, 255, 232, 0.616);;
  }

  .bDelete{
    background: -webkit-linear-gradient(0deg,white 10%, #DC3545 10%);
    color: white;
    background-size: auto auto;
    border: 4px solid #DC3545;
  }

  .bDelete:hover{
    background-position: 200px 0;
    box-shadow: 0 20px 16px 0 rgba(32, 28, 26, 0.616),
      0 8px 50px 0 rgba(209, 255, 232, 0.616);
  }

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(185, 181, 181);
  border-radius: 10px;
}

.biggerSwitch.form-switch .form-check-input { 
  width: 7em !important;
  height: 4em;
}

.middleSwitch.form-switch .form-check-input { 
  width: 3em !important;
  height: 2em;
}

.GeeksForGeeks {
  background-image: 
  url(
"https://rare-gallery.com/uploads/posts/891136-Lake-Lucerne-Switzerland-Lake-Island-Crag.jpg");
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat:no-repeat; 
}

.box1 {
  background-color: #000;
  color: #fff;
  opacity: .5;
}

.insideBox {
  background-color: #fffefe;
  opacity: none !important;
}

/* .stylesss {
  color: hsl(0, 0%, 28%);
  font-size: 35px;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 7px;
  cursor: pointer;
}

.stylesss span {
  transition: 0.5s ease-out;
}
.stylesss:hover span:nth-child(1) {
  margin-right: 5px;
}
.stylesss:hover span:nth-child(1):after {
  content: "'";
}
.stylesss:hover span:nth-child(2) {
  margin-left: 30px;
}
.stylesss:hover span {
  color: #fff;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
} */

/* .stylesss2 {
  color: hsl(0, 0%, 28%);
  font-size: 50px!important;
  font-weight: bold!important;
  font-family: monospace;
  letter-spacing: 7px!important;
  cursor: pointer;
  text-transform: uppercase;
}

.stylesss2 {
  padding: 64px;
  background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 600px;
  }
} */
