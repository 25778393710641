.tableFixed table {
    display: flex;
    flex-flow: column;
    width: 100%;
}
  
.tableFixed thead {
flex: 0 0 auto;
}
  
.tableFixed tbody {
flex: 1 1 auto;
display: block;
overflow-y: auto;
overflow-x: hidden;
height: calc(100vh - 200px);
}
  
.tableFixed tr {
width: 100%;
display: table;
table-layout: fixed;
}